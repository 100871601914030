<template>
    <div class="d-flex flex-column flex-root">
        <!-- begin:: Header Mobile -->
        <KTHeaderMobile style="z-index:2"></KTHeaderMobile>
        <!-- end:: Header Mobile -->
        <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
        <!-- begin::Body -->
        <div class="d-flex flex-row flex-column-fluid page">
            <!-- begin:: Aside Left -->
            <KTAdminAside style="z-index:1001" v-if="asideEnabled"></KTAdminAside>
            <!-- end:: Aside Left -->
            <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
                <div
                    v-if="
                        () => {
                            window.screen.availWidth > 992;
                        }
                    "
                    class="adminTopBar adminTopBarRelative"
                >
                    <AdminTopBar></AdminTopBar>
                </div>
                <!-- begin:: Content -->
                <div id="kt_content" class="content d-flex flex-column flex-column-fluid">
                    <!-- begin:: Content Head -->
                    <!-- end:: Content Head -->
                    <!-- begin:: Content Body -->
                    <div class="d-flex flex-column-fluid mt-5">
                        <div
                            :class="{
                                'container-fluid': contentFluid,
                                container: !contentFluid
                            }"
                            style="max-width:95%"
                        >
                            <transition name="fade-in-up">
                                <router-view />
                            </transition>
                        </div>
                    </div>
                </div>
                <!-- begin:: Content Footer -->
                <!-- end:: Content Footer -->
            </div>
        </div>
        <KTScrollTop></KTScrollTop>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Loader from '@/view/content/Loader.vue';
import KTAdminAside from '@/view/layout/admin/aside/AdminAside.vue';
import KTHeaderMobile from '@/view/layout/header/HeaderMobile.vue';
import HtmlClass from '@/core/services/htmlclass.service';
import AdminTopBar from '@/view/layout/admin/header/AdminTopBar.vue';
import KTScrollTop from '@/view/layout/extras/ScrollTop';

import { ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME } from '@/core/services/store/htmlclass.module.js';

export default {
    name: 'AdminLayout',
    components: {
        KTAdminAside,
        KTHeaderMobile,
        AdminTopBar,
        KTScrollTop,
        Loader
    },
    beforeMount() {
        // show page loading
        this.$store.dispatch(ADD_BODY_CLASSNAME, 'page-loading');

        // initialize html element classes
        HtmlClass.init(this.layoutConfig());
    },
    mounted() {
        // check if current user is authenticated
        /*if (!this.isAuthenticated) {
        this.$router.push({ name: "login" });
        alert('connected');
    }*/
        // Simulate the delay page loading
        setTimeout(() => {
            // Remove page loader after some time
            this.$store.dispatch(REMOVE_BODY_CLASSNAME, 'page-loading');
        }, 2000);
    },
    methods: {},
    computed: {
        ...mapGetters(['isAuthenticated', 'breadcrumbs', 'pageTitle', 'layoutConfig']),

        /**
         * Check if the page loader is enabled
         * @returns {boolean}
         */
        loaderEnabled() {
            return !/false/.test(this.layoutConfig('loader.type'));
        },

        /**
         * Check if container width is fluid
         * @returns {boolean}
         */
        contentFluid() {
            return this.layoutConfig('content.width') === 'fluid';
        },

        /**
         * Page loader logo image using require() function
         * @returns {string}
         */
        loaderLogo() {
            return process.env.BASE_URL + this.layoutConfig('loader.logo');
        },

        /**
         * Check if the left aside menu is enabled
         * @returns {boolean}
         */
        asideEnabled() {
            return !!this.layoutConfig('aside.self.display');
        },

        /**
         * Set the right toolbar display
         * @returns {boolean}
         */
        toolbarDisplay() {
            return !!this.layoutConfig('toolbar.display');
            //return true;
        },

        /**
         * Set the subheader display
         * @returns {boolean}
         */
        subheaderDisplay() {
            return !!this.layoutConfig('subheader.display');
        },

        scrollY() {
            return window.screenY;
        }
    }
};
</script>
<style>
.adminTopBar {
    width: 100%;
    z-index: 100;
    background-color: white;
    margin-left: 2px;
    top: 0;
    left: 0;
}
.adminTopBarRelative {
    position: relative;
}
.adminTopBarFixed {
    position: fixed;
}
@media (max-width: 991.9px) {
    .adminTopBar {
        display: none;
    }
}
</style>
